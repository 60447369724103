import '@assets/scss/App.scss';
import { useEffect } from 'react';
import '@assets/scss/base/export.scss';
import '@assets/scss/base/RSuite.scss';
import '@assets/less/rsuite.config.less';
import '@assets/scss/base/tailwind.scss';
import { CONFIG } from './Config/constants';
import { isDev } from '../Tools/Utils/React';
import useStore from '@src/Tools/Store/useStore';
import RoutesRenderer from './Routes/RouteRenderer';
import Client from 'shopify-buy/index.unoptimized.umd';
import { setShopifyClient } from '@src/Tools/Store/actions/LocalCacheActions';
import useCacheInvalidator from '@src/Tools/Hooks/useCacheInvalidator';

const App = () => {
	const { dispatch } = useStore();
	const client: ShopifyBuy = Client.buildClient({
		domain: CONFIG.SHOPIFY_DOMAIN,
		storefrontAccessToken: CONFIG.SHOPIFY_STOREFRONT_API_ACCESS_TOKEN,
		apiVersion: CONFIG.SHOPIFY_API_VERSION,
	});

	//? ------------------------------ useEffects ------------------------------------------------------

	useEffect(() => {
		isDev && (window.logs = true);
		dispatch(setShopifyClient(client));
	}, []);

	// -------------------------------------------------------------------------------------------------
	useCacheInvalidator();
	return <RoutesRenderer />;
};

export default App;
