import { useEffect } from 'react';
import useStore from '../../Store/useStore';
import { CONFIG } from '@src/App/Config/constants';
import { removeFaIconCache } from '../../Utils/Cache';
import { setOrderDetails } from '../../Store/actions/LocalCacheActions';
import preval from 'preval.macro';

//* Build time
const cur_version = preval`module.exports = new Date().getTime();`;

//* Promotion Status
const cur_is_promotion = `${CONFIG.IS_PROMOTION}`;

export const checkForCacheInvalidation = () => {
	let isInvalidate = false;
	const pre_version = localStorage.getItem('BUILD_VERSION');
	if (!pre_version) isInvalidate = true;

	if (pre_version && +pre_version < +cur_version) {
		const pre_is_promotion = localStorage.getItem('CUR_IS_PROMOTION');
		if (!pre_is_promotion) isInvalidate = true;
		if (pre_is_promotion && pre_is_promotion !== cur_is_promotion) isInvalidate = true;
		localStorage.setItem('CUR_IS_PROMOTION', cur_is_promotion);
	}
	localStorage.setItem('BUILD_VERSION', cur_version);
	return isInvalidate;
};

const useCacheInvalidator = () => {
	const { dispatch } = useStore();

	const onInvalidate = () => {
		dispatch(setOrderDetails({}));
		removeFaIconCache();
		// localStorage?.clear();
	};

	useEffect(() => {
		const isInvalidated = checkForCacheInvalidation();
		if (!isInvalidated || window.MODE === 'LOCAL') return;
		onInvalidate();
		console.log('✅🔃 Assets cache refreshed successfully!');
	}, []);
};

export default useCacheInvalidator;
